import React from "react"
import styled from "styled-components"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import { Typography } from "antd"

const { Title, Paragraph, Text } = Typography

export default class Datenschutz extends React.Component<any, any> {
  public render() {
    return (
      <Layout>
        <SEO title="Datenschutz" />
        <SectionsWrapper>
          <section className="main-section">
            <div className="wrapper">
              <Paragraph>
              <Title level={4}> I. Name und Anschrift des Verantwortlichen</Title> 
                easypak Umzüge GmbH<br/>
                Bottwartalstr. 62<br/>
                71642 Ludwigsburg<br/>

                Telefon: <a href="tel:+49 7141 9163600"> +49 (0) 7141 9163600</a><br/>
                E-Mail: <a href="mailto:info@easypak-umzug.de"> info@easypak-umzug.de</a><br/>
                Website: <a href="https://www.easypak-umzug.de" target="_blank"> www.easypak-umzug.de</a><br/>

                ist der Verantwortliche im Sinne der EU-Datenschutz-Grundverordnung (DSGVO) und anderer nationaler Datenschutzgesetze.<br/>
                <br/>
                <Title level={4}>II. Allgemeine Informationen zur Datenverarbeitung</Title>
                1. Umfang der Verarbeitung personenbezogener Daten<br/>

                Personenbezogene Daten der Nutzer unserer Website erheben und verwenden wir grundsätzlich nur, soweit das zur Bereitstellung einer funktionsfähigen Website, unserer Inhalte und Leistungen erforderlich ist. Grundsätzlich erfolgt die Erhebung und Verwendung personenbezogener Daten unserer Nutzer nur nach dessen Einwilligung. Eine Ausnahme von diesem Grundsatz gilt in den Fällen, in denen eine Verarbeitung der Daten durch gesetzliche Vorschriften gestattet oder die Einholung einer vorherigen Einwilligung aus tatsächlichen Gründen nicht möglich ist.
                <br/>

                2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten<br/>

                Die Rechtsgrundlagen für die Verarbeitung von personenbezogenen Daten ergeben sich grundsätzlich aus:<br/>

                • Art. 6 Abs. 1 lit. a DSGVO bei Einholung einer Einwilligung der betroffenen Person.<br/>

                • Art. 6 Abs. 1 lit. b DSGVO bei Verarbeitungen, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, dienen. Miterfasst sind hier Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                <br/>
                • Art. 6 Abs. 1 lit. c DSGVO bei Verarbeitungen, die zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind.<br/>

                • Art. 6 Abs. 1 lit. d DSGVO, falls lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen.
                <br/>
                • Art. 6 Abs. 1 lit. f DSGVO, wenn die Verarbeitung zur Wahrung eines berechtigte Interesses unseres Unternehmens oder eines Dritten erforderlich ist und die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht überwiegen.
                <br/>

                3. Datenlöschung und Speicherdauer<br/>

                Die personenbezogenen Daten der Nutzer werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine darüber hinaus gehende Speicherung kann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.
                <br/>
                Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfullung besteht.
                <br/><br/>
                <Title level={4}>III. Nutzung unsere Webseite, Allgemeine Informationen</Title>
                1. Beschreibung und Umfang der Datenverarbeitung<br/>

                Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom Computersystem des Nutzers. Dabei werden folgende Information erhoben:
                <br/>
                (1) Informationen über den Browsertyp und die verwendete Version<br/>

                (2) Das Betriebssystem des Nutzers<br/>

                (3) Den Internet-Service-Provider des Nutzers<br/>

                (4) Die IP-Adresse des Nutzers<br/>

                (5) Datum und Uhrzeit des Zugriffs<br/>

                (6) Websites, von denen das System des Nutzers auf unsere Internetseite gelangt<br/>

                (7) Websites, die vom System des Nutzers über unsere Website aufgerufen werden<br/>

                Die beschriebenen Daten werden in den Logfiles unseres Systems gespeichert. Es findet keine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers statt.
                <br/>

                2. Zweck und Rechtsgrundlage für die Datenverarbeitung<br/>

                Die vorübergehende Speicherung der IP-Adresse durch unser System ist erforderlich, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
                <br/>
                Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO. Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Die Erfassung ihrer personenbezogenen Daten zur Bereitstellung unseres Internetauftritts und die Speicherung der Daten in Logfiles ist für den Betrieb der Website zwingend erforderlich. Eine Widerspruchsmöglichkeit des Nutzers besteht daher nicht.
                <br/>

                3. Dauer der Speicherung<br/>

                Ihre Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Sofern Ihre Daten erfasst werden, um die Bereitstellung der Website zu gewährleisten, werden die Daten gelöscht, wenn die jeweilige Sitzung beendet ist. Werden Ihre Daten in Logfiles gespeichert, erfolgt die Löschung nach spätestens sieben Tagen. Eine darüberhinausgehende Speicherung ist möglich, wobei die die IP-Adressen der Nutzer in diesem Fall gelöscht oder verfremdet werden. Eine Zuordnung des aufrufenden Clients ist so nicht mehr möglich.
                <br/>
                <br/>
                <Title level={4}>IV. Allgemeine Informationen zur Verwendung von Cookies</Title>
                Auf unserer Webseite verwenden wir Cookies. Unter Cookies versteht man Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Rufen Sie eine Website auf, so kann ein Cookie auf Ihrem Betriebssystem gespeichert werden. Dieser enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. Cookies werden von uns eingesetzt, um unsere Homepage nutzerfreundlicher zu gestalten.
                <br/>
                Einige Elemente unserer Website erfordern, dass der Browser auch nach einem Seitenwechsel identifiziert werden kann. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO. Zweck der Verwendung der technisch notwendigen Cookies ist die Vereinfachung der Nutzung unserer Internetseite. Nutzerdaten, die durch technisch notwendige Cookies erhobenen werden, verwenden wir nicht zur Erstellung von Nutzerprofilen.
                <br/>
                Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Als Nutzer haben Sie folglich die Kontrolle über die Verwendung von Cookies. Sie können die Übertragung von Cookies einschränken oder deaktivieren, in dem Sie Änderungen in den Einstellungen Ihres Internetbrowsers vornehmen. Dort können auch gespeicherte Cookies wieder gelöscht werden. Bitte beachten Sie, dass Sie unter Umständen nicht mehr alle Funktionen unserer Internetseite nutzen können, falls Sie Cookies deaktivieren.
                <br/><br/>
                <Title level={4}>V. Ihre Rechte / Rechte des Betroffenen</Title>
                Nach der EU-Datenschutz-Grundverordnung haben Sie als Betroffener folgende Rechte:
                <br/>
                1. Auskunftsrecht<br/>

                Sie haben das Recht, von uns als Verantwortliche die Information zu erhalten, ob wir personenbezogene Daten, die Sie betreffen, verarbeiten. Darüber hinaus könnten Sie über folgende Informationen Auskunft verlangen:
                <br/>
                (1) Zweck der Datenverarbeitung;<br/>

                (2) die Kategorien der verarbeiteten personenbezogenen Daten;<br/>

                (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
                <br/>
                (4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
                <br/>
                (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
                <br/>
                (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
                <br/>
                (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
                <br/>
                (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person. Schließlich haben Sie auch das Recht, Auskunft darüber zu verlangen, ob Ihre personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Fall können Sie Informationen über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung verlangen.
                <br/>
                Ihren Auskunftanspruch könnten Sie geltend machen unter:<br/>
                <a href="mailto:info@easypak-umzug.de"> info@easypak-umzug.de</a><br/>

                <br/>
                2. Recht auf Berichtigung<br/>

                Sollten die von uns verarbeiten und Sie betreffenden personenbezogenen Daten unrichtig oder unvollständig sein, haben Sie uns gegenüber ein Recht auf Berichtigung und/oder Vervollständigung. Die Berichtigung wird unverzüglich vorgenommen.
                <br/>
                <br/>
                3. Recht auf Einschränkung<br/>

                Das Recht auf Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten kann in folgenden Fällen geltend gemacht werden:
                <br/>
                (1) die Richtigkeit der personenbezogenen Daten wird für eine Dauer bestritten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
                <br/>
                (2) die Verarbeitung ist unrechtmäßig und die Löschung der personenbezogenen Daten wird ablehnt, wobei stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt wird;
                <br/>
                (3) der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, der Betroffene benötigt diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, oder
                <br/>
                (4) der Betroffene hat Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber den Gründen des Betroffenen überwiegen. Falls die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt wurde, dürfen diese Daten – abgesehen von ihrer Speicherung– nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden. Bei Vorliegen einer Einschränkung der Verarbeitung nach den dargestellten Grundsätzen, werden Sie von uns unterrichtet bevor die Einschränkung aufgehoben wird.
                <br/>
                <br/>
                4. Recht auf Löschung<br/>

                Bei Vorliegen der nachfolgend dargestellten Gründe können Sie verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden. Der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen. Die Gründe sind:
                <br/>
                (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                <br/>
                (2) Die Verarbeitung ist auf eine Einwilligung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO geschützt und Sie widerrufen die Einwilligung. Weitere Voraussetzung ist, dass es keine anderweitige Rechtsgrundlage für die Verarbeitung gibt.
                <br/>
                (3) Sie legen Widerspruch gegen die Verarbeitung (Art. 21 Abs. 1 DSGVO) ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor. Eine weitere Möglichkeit ist, dass Sie einen Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 2 DSGVO einlegen.
                <br/>
                (4) Die Verarbeitung der Sie betreffenden personenbezogenen Daten erfolgt unrechtmäßig.
                <br/>
                (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
                <br/>
                (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben. Haben wir die Sie betreffenden personenbezogenen Daten öffentlich gemacht und sind wir gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als Betroffener die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
                <br/>
                Wir weisen darauf hin, dass das Recht auf Löschung nicht besteht, soweit die Verarbeitung erforderlich ist
                <br/>
                (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                <br/>
                (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                <br/>
                (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                <br/>
                (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
                <br/>
                (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                <br/>

                5. Recht auf Unterrichtung
                <br/>
                Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung geltend gemacht, wird wir verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ferner steht Ihnen das Recht zu, über diese Empfänger unterrichtet zu werden.
                <br/>

                6. Recht auf Datenübertragbarkeit
                <br/>
                Sie haben nach der DSGVO ferner das Recht, die uns bereitgestellten, Sie betreffenden personenbezogenen Daten, in einem strukturierten, gängigem und maschinenlesbarem Format zu erhalten. Weiterhin haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
                <br/>
                • die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
                <br/>
                • die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                <br/>
                Im Rahmen der Ausübung des Rechts auf Datenübertragbarkeit haben Sie schließlich das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist sowie dabei Freiheiten und Rechte anderer Personen nicht beeinträchtigt werden. Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                <br/>
                <br/>
                7. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
                <br/>
                Ihnen steht das Recht zu, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Wir weisen darauf hin, dass durch den Widerruf der Einwilligung die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt wird.
                <br/>
                <br/>
                8. Recht auf Widerspruch
                <br/>
                Weiterhin haben Sie das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen. Das Widerspruchsrecht gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                <br/>
                Werden die Sie betreffenden personenbezogenen Daten zum Zwecke der verarbeitet, steht Ihnen das Recht zu, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Bei einem Widerspruch gegen die Verarbeitung für Zwecke der Direktwerbung, werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet. Sie haben ferner die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft (ungeachtet der Richtlinie 2002/58/EG) Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
                <br/>
                <br/>
                9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
                <br/>
                Nach der EU-Datenschutz-Grundverordnung steht Ihnen weiterhin das Recht zu, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Eine Ausnahme von diesem Grundsatz besteht allerdings, wenn die Entscheidung
                <br/>
                (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,
                <br/>
                (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
                <br/>
                (3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
                <br/>
                Erfolgt die Verarbeitung im Rahmen der in (1) und (3) genannten Fälle, so trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren. Hierzu gehört mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört. Die Entscheidung nach (1) – (3) dürfen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
                <br/>
                <br/>
                10. Recht auf Beschwerde bei einer Aufsichtsbehörde
                <br/>
                Sind Sie der Auffassung, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie schließlich das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes.
                <br/><br/>
                <Title level={4}>VI. Elektronische Kontaktaufnahme</Title>
                Falls Sie uns kontaktieren, steht Ihnen auf unserer Homepage ein Bewerbungsformular zur Verfügung, welches Sie für die elektronische Bewerbung nutzen können. Die in die Eingabemaske eingegebenen Daten werden dabei an uns übermittelt und gespeichert.
                <br/>
                Diese Daten sind:
                <br/>
                (1) Name des Bewerbers
                <br/>
                (2) Eintrittsdatum des Bewerbers
                <br/>
                (3) E-Mail-Adresse des Bewerbers
                <br/>
                (4) Telefonnummer des Bewerbers
                <br/>
                (5) Gehaltswunsch des Bewerbers
                <br/>
                (6) Fähigkeiten des Bewerbers
                <br/>
                (7) Bewerbungsunterlagen
                <br/>
                <br/>
                Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:
                <br/>
                (1) Die IP-Adresse des Nutzers
                <br/>
                (2) Datum und Uhrzeit der Registrierung
                <br/>
                Ferner besteht die Möglichkeit, uns über die bereitgestellte E-Mail-Adresse zu kontaktieren. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert. Eine Weitergabe Ihrer Daten an Dritte findet in diesem Zusammenhang nicht statt, die Daten werden ausschließlich für die Verarbeitung der Kommunikationsaufnahme verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
                <br/>
                Die Verarbeitung der personenbezogenen Daten dient in diesem Zusammenhang allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten. Sollten während des Absendevorgangs weitere personenbezogene Daten verarbeitet werden, so dienen diese nur dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen. Ihre Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist.
                <br/>
                Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist. Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer Frist von sieben Tagen gelöscht. Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Auch bei der Kontaktaufnahme per E-Mail Kontakt können Sie der Speicherung Ihrer personenbezogenen Daten jederzeit widersprechen. Wir weisen jedoch darauf hin, dass in einem solchen Fall die Konversation nicht fortgeführt werden kann. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall gelöscht.
                <br/><br/>
                <Title level={4}>VII. Web Analytics</Title>
                1. Google Tag Manager
                <br/>
                Diese Webseite benutzt den Google Tag Manager der Google Inc. Dieser Manager ist eine Lösung, mit der wir sogenannte Website-Tags über eine Oberfläche verwalten können. Dadurch können wir Marketing-Dienste von Google, wie zum Beispiel Google Analytics, oder auch externe Angebote, wie Facebook Pixel, in unsere Webseite einbinden. Durch den Tag Manager, welcher die einzelnen Tags implementiert, werden keine personenbezogenen Daten der Webseiten-Nutzer verarbeitet.
                <br/>
                Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer, welche durch die einzelnen Marketing-Dienste verarbeitet werden, wird auf die jeweiligen Punkte im Rahmen dieser Datenschutzerklärung verwiesen. Nutzungsrichtlinien: <a href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank"> www.google.com/intl/de/tagmanager/use-policy.html</a>.
                <br/>
                <br/>
                2. Einsatz von MARKSALE®
                <br/>
                Auf dieser Webseite werden Daten zu Marketing- und Optimierungszwecken mithilfe der Technologie von MARKSALE® erhoben und verarbeitet. Eine Speicherung und Verarbeitung dieser Daten erfolgt anhand anonymer oder pseudonymer Profile.
                <br/>
                Diese Nutzerprofile werden in Cookies gespeichert. Zusätzlich zu den bei Zugriff auf die Webseite gespeicherten Daten betrifft dies auch Daten über die Webseite, von der aus Sie auf unsere Webseite gelangen, die Webseiten, die sie während des Aufrufs unserer Webseite besuchen, und gegebenenfalls Suchbegriffe, die Sie verwendet haben, um unsere Webseite zu finden.
                <br/>
                Insofern personenbezogene Daten gegeben sind, beruht die Verarbeitung auf gesetzlichen Vorschriften, die eine Verarbeitung erlauben, weil entweder eine ausdrücklich Einwilligung nach Art. 6 Absatz 1 Satz 1 lit. a DSGVO vorliegt oder ein legitimes Interesse gemäß Art. 6 Absatz 1 Satz 1 lit. f DSGVO gegeben ist, wodurch die Webseite auf die Bedürfnisse des Nutzers zugeschnitten wird; oder die Daten in pseudonymer Form für Marketing- oder Optimierungszwecke genutzt werden und der Nutzer dieser Verwendung jeweils nicht widersprochen hat. Die Daten werden nicht zur Identifizierung der Person eines Users genutzt und nicht mit anderen personenbezogenen Daten über diesen User zusammengeführt.
                <br/>
                Widerspruch gegen Datenspeicherung
                <br/>
                a) Anhand des folgenden Links können Sie der Verarbeitung Ihrer Daten widersprechen.<br/>
                Durch das Klicken auf den Link wird ein sogenanntes Opt-Out-Cookie auf Ihrem Datenträger gespeichert, durch das MARKSALE® erkennen kann, dass Sie der Speicherung eines (pseudonymisierten) Nutzungsprofils widersprechen. Bitte beachten: Sollten Sie die von Ihrem Browser gespeicherten Cookies löschen, geht auch das Opt-Out-Cookie verloren. In diesem Fall müssen Sie den Link zum Widerspruch erneut anklicken.
                <br/>
                b) Sie können der Speicherung von Cookies auch anhand einer entsprechenden Einstellung in Ihrem Browser widersprechen; wir weisen jedoch darauf hin, dass Sie dann möglicherweise nicht alle Funktionen dieser Webseite vollständig nutzen können.
                <br/>
                In der Datenschutzerklärung von MARKSALE® finden Sie weitere Informationen zum Webtracking und der Datenverarbeitung: <a href="https://www.marksale.de/datenschutz" target="_blank"> www.marksale.de/datenschutz</a>
                <br/><br/>
                <Title level={4}>VIII. Online-Werbung</Title>
                1. Einsatz von Google Adwords Conversion
                <br/>
                Wir nutzen das Angebot von Google Adwords, um mit Hilfe von Werbemitteln (sogenannten Google Adwords) auf externen Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Wir können in Relation zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die einzelnen Werbemaßnahmen sind. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, unsere Website für Sie interessanter zu gestalten und eine faire Berechnung von Werbe-Kosten zu erreichen. Diese Werbemittel werden durch Google über sogenannte „Ad Server“ ausgeliefert. Dazu nutzen wir Ad Server Cookies, durch die bestimmte Parameter zur Erfolgsmessung, wie Einblendung der Anzeigen oder Klicks durch die Nutzer, gemessen werden können.
                <br/>
                Sofern Sie über eine Google-Anzeige auf unsere Website gelangen, wird von Google Adwords ein Cookie in ihrem PC gespeichert. Diese Cookies verlieren in der Regel nach 30 Tagen ihre Gültigkeit und sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu diesem Cookie werden in der Regel als Analyse-Werte die Unique Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency), letzte Impression (relevant für Post-View-Conversions) sowie Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr angesprochen werden möchte) gespeichert. Diese Cookies ermöglichen Google, Ihren Internetbrowser wiederzuerkennen. Sofern ein Nutzer bestimmte Seiten der Webseite eines Adwords-Kunden besucht und das auf seinem Computer gespeicherte Cookie noch nicht abgelaufen ist, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
                <br/>
                Jedem Adwords-Kunden wird ein anderes Cookie zugeordnet. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Wir selbst erheben und verarbeiten in den genannten Werbemaßnahmen keine personenbezogenen Daten. Wir erhalten von Google lediglich statistische Auswertungen zur Verfügung gestellt. Anhand dieser Auswertungen können wir erkennen, welche der eingesetzten Werbemaßnahmen besonders effektiv sind. Weitergehende Daten aus dem Einsatz der Werbemittel erhalten wir nicht, insbesondere können wir die Nutzer nicht anhand dieser Informationen identifizieren. Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von AdWords Conversion erhält Google die Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben.
                <br/>
                Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert. Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern:
                <br/>
                a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten;
                <br/>
                b) durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden, <a href="https://www.google.de/settings/ads" target="_blank"> www.google.de/settings/ads</a> , wobei diese Einstellung gelöscht werden, wenn Sie Ihre Cookies löschen;
                <br/>
                c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href="https://www.aboutads.info/" target="_blank"> www.aboutads.info</a>  choices, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen;
                <br/>
                d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link <a href="https://www.google.com/settings/ads/plugin" target="_blank"> www.google.com/settings/ads/plugin</a>. Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.
                <br/>
                Rechtsgrundlage für die Verarbeitung Ihrer Daten ist ist Art. 6 Abs. 1 S. 1 lit. f DSGVO oder bei Einwilligung Art. 6 Abs. 1 S. 1 lit. a DSGVO. Weitere Informationen zum Datenschutz bei Google finden Sie hier: <a href="https://www.google.com/intl/de/policies/privacy" target="_blank"> www.google.com/intl/de/policies/privacy</a>  und <a href="https://services.google.com/sitestats/de.html" target="_blank"> services.google.com/sitestats/de.html</a>. Alternativ können Sie die Webseite der Network Advertising Initiative (NAI) unter <a href="https://www.networkadvertising.org" target="_blank"> www.networkadvertising.org</a>  besuchen. Google hat sich dem EU-US Privacy Shield unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank"> www.privacyshield.gov/EU-US-Framework</a>.
                <br/>
                <br/>
                2. Remarketing
                <br/>
                Neben Adwords Conversion nutzen wir die Anwendung Google Remarketing. Hierbei handelt es sich um ein Verfahren, mit dem wir Sie erneut ansprechen möchten. Durch diese Anwendung können Ihnen nach Besuch unserer Website bei Ihrer weiteren Internetnutzung unsere Werbeanzeigen eingeblendet werden. Dies erfolgt mittels in Ihrem Browser gespeicherter Cookies, über die Ihr Nutzungsverhalten bei Besuch verschiedener Websites durch Google erfasst und ausgewertet wird. So kann von Google Ihr vorheriger Besuch unserer Website festgestellt werden.
                <br/>
                Eine Zusammenführung der im Rahmen des Remarketings erhobenen Daten mit Ihren personenbezogenen Daten, die ggf. von Google gespeichert werden, findet durch Google laut eigenen Aussagen nicht statt. Insbesondere wird laut Google beim Remarketing eine Pseudonymisierung eingesetzt.
                <br/>
                <br/>
                3. Facebook-Pixel
                <br/>
                Weiterhin verwendet die Website die Remarketing-Funktion „Custom Audiences“ der Facebook Inc. („Facebook“) mit Hilfe des sogenannten Facebook Pixel. Dadurch können Nutzern der Website im Rahmen des Besuchs des sozialen Netzwerkes Facebook oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („Facebook-Ads“) dargestellt werden. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten.
                <br/>
                Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Facebook auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Facebook erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung des Facebook Pixels erhält Facebook die Information, dass Sie die entsprechende Webseite unseres Internetauftritts aufgerufen haben, oder eine Anzeige von uns auf der Webseite von Facebook angeklickt haben.
                <br/>
                Mit Hilfe des Facebook-Pixels möchten wir sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden.
                <br/>
                Sofern Sie bei einem Dienst von Facebook registriert sind, kann Facebook den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Facebook registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert.
                <br/>
                Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                <br/>
                Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank"> www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>).
                <br/>
                Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen der Datenverwendungsrichtlinie von Facebook. Dementsprechend finden Sie generelle Hinweise zur Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von Facebook: <a href="https://www.facebook.com/policy" target="_blank"> www.facebook.com/policy</a>. Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie im Hilfebereich von Facebook: <a href="https://www.facebook.com/business/help/651294705016616" target="_blank"> www.facebook.com/business/help/651294705016616</a>.
                <br/>
                Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads widersprechen. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden, können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen nutzungsbasierter Werbung befolgen: <a href="https://www.facebook.com/settings?tab=ads" target="_blank"> www.facebook.com/settings?tab=ads</a>. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen. Für Nutzer unserer Webseite, die nicht bei Facebook registriert sind, kann die Funktion „Facebook Custom Audiences“ über diesen Link deaktiviert werden.
                <br/><br/>
                <Title level={4}>IX. Social Media</Title>
                1. Social Media Präsenz
                <br/>
                Wir unterhalten Fanpages innerhalb verschiedener sozialer Netzwerke und Plattformen mit dem Ziel, mit den dort aktiven Kunden, Interessenten und Nutzern zu kommunizieren und sie dort über unsere Leistungen zu informieren.
                <br/>
                Wir weisen darauf hin, dass dabei Ihre personenbezogenen Daten außerhalb der Europäischen Union verarbeitet werden können, sodass sich hierbei Risiken für Sie ergeben können (etwa bei der Durchsetzung Ihrer Rechte nach europäischen / deutschen Recht). Bitte beachten Sie, dass einige US-Anbieter  unter dem Privacy-Shield zertifiziert sind und sich damit verpflichten haben, die Datenschutzstandards der EU einzuhalten.
                <br/>
                Die Daten der Nutzer werden im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Diese Nutzungsprofile können wiederum dazu verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
                <br/>
                Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern um eine Einwilligung in die Datenverarbeitung gebeten werden (d.h. ihr Einverständnis z.B. über das Anhaken eines Kontrollkästchens oder Bestätigung einer Schaltfläche erklären) ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
                <br/>
                Weitere Informationen zu der Verarbeitung Ihrer personenbezogenen Daten sowie zu Ihren Widerspruchsmöglichkeiten erhalten Sie unter den unten aufgeführten Links des jeweiligen Anbieters. Die Geltendmachung von Auskunfts- und weiteren Rechten der Betroffenen können ebenfalls gegenüber den Anbietern erfolgen, dann nur die direkten Zugriff auf die Daten der Nutzer haben und über entsprechende Informationen verfügen. Selbstverständlich stehen wir bei Rückfragen zur Verfügung und unterstützen Sie, falls Sie Hilfe benötigen. 
                <br/>
                Facebook<br/>
                Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland<br/>
                Datenschutzerklärung: <a href="https://www.facebook.com/about/privacy/" target="_blank"> www.facebook.com/about/privacy/</a><br/>
                Opt-Out: <a href="https://www.facebook.com/settings" target="_blank"> www.facebook.com/settings</a> und <a href="http://www.youronlinechoices.com" target="_blank"> www.youronlinechoices.com</a><br/>
                Privacy Shield: <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank"> www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>.<br/>
                <br/>
                Instagram<br/>
                Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA<br/>
                Datenschutzerklärung/Opt-Out: <a href="http://instagram.com/about/legal/privacy/" target="_blank"> instagram.com/about/legal/privacy/</a>.<br/>
                <br/>
                LinkedIn<br/>
                LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland<br/>
                Datenschutzerklärung <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"> www.linkedin.com/legal/privacy-policy</a><br/>
                Opt-Out: <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank"> www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>,<br/>
                PrivacyShield:  <a href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active" target="_blank"> www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active</a>.<br/>
                </Paragraph>
            </div>
          </section>
        </SectionsWrapper>
      </Layout>
    )
  }
}

const SectionsWrapper = styled.div`
  padding-top: 130px;
  .wrapper {
    overflow: hidden;
  }
  a {
    color: rgba(0, 0, 0, 0.65);
    :hover {
      color: #ffc927;
    }
  }
`
